const themeGen = (theme) => {
  const res = {
    conf: {
      showSideNavigationOnStaticPages: true,
      showTitleOnContentPageDesktop: true,
      showTitleOnContentPageMobile: true,
      showHomepageCategorySection: false,
    },
    sideContentWidth: '22%',
    sideContentPadding: 'px-4',
    linkColor: theme.col.vars.textLinkColor,
    linkColorHover: theme.col.secondary.peer,
  };
  return res;
};

export default themeGen;
